import MainRoutes from "./routes";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.css";

import "./App.css";
import React, { useState } from "react";
import { MySnackBarContext } from "./components/MySnackBar";

import { AlertContext } from "./contexts/AlertContext";

function App() {
  const [alertData, setAlertData] = useState({
    open: false,
    severity: "",
    message: "",
  });
  return (
    <AlertContext.Provider value={{ alertData, setAlertData }}>
      <MainRoutes />
      <MySnackBarContext alertData={alertData} setAlertData={setAlertData} />
    </AlertContext.Provider>
  );
}

export default App;
