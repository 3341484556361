import React, { useState, useContext } from "react";
import { Divider, TextField, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileText } from "@fortawesome/free-regular-svg-icons";
import logo from "../images/logo.png";
import { LoadingButton } from "@mui/lab";
import { httpService } from "../httpService";
import { AlertContext } from "../contexts/AlertContext";

export default function HomePage() {
  const { setAlertData } = useContext(AlertContext);
  const [loading, setLoading] = useState(false);
  const [examinationNumber, setExaminationNumber] = useState("");
  const getPrintout = async (e) => {
    e.preventDefault();
    setLoading(true);

    const { data, error } = await httpService.get(
      examinationNumber.toUpperCase().replaceAll("/", "-")
    );
    if (data && data.error) {
      setLoading(false);
      return setAlertData({
        open: true,
        message: data.error,
        severity: "error",
      });
    }
    if (data) {
      //console.log(data);
      window.location.assign(
        `/printout/${examinationNumber.toUpperCase().replaceAll("/", "-")}`
      );
    }

    if (error) {
      setAlertData({ open: true, message: error, severity: "error" });
    }
    setLoading(false);
  };
  return (
    <div>
      {/* desktop */}
      <div className="d-none d-lg-block">
        <div className="row m-0">
          <div className="col-lg-8 d-flex homeImg justify-content-center align-items-center text-white text-center">
            <div>
              <div className="mb-2">
                <img src={logo} height={150} width={150} alt="logo" />
              </div>
              <Typography
                variant="h2"
                textTransform={"capitalize"}
                fontWeight={700}
                gutterBottom
              >
                Examination Slip <FontAwesomeIcon icon={faFileText} />
              </Typography>
              <hr />
              <div className="mt-2">
                <Typography fontWeight={300}>NMCN</Typography>
              </div>
            </div>
          </div>
          <div className="col-lg-4 d-flex align-items-center">
            <div className="col-lg-10">
              <div className="mb-3">
                <Typography>Enter a candidate's examination number</Typography>
              </div>
              <form onSubmit={getPrintout}>
                <TextField
                  onChange={(e) => setExaminationNumber(e.target.value)}
                  fullWidth
                  label="Enter Examination Number"
                />
                <div className="mt-3 text-center">
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={loading}
                  >
                    get slip
                  </LoadingButton>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ minHeight: "90vh" }}
        className="d-sm-block d-md-none container d-flex align-items-center "
      >
        <div className="col-12">
          <div className="mb-2 mt-4 mb-4 text-center">
            <img src={logo} height={100} width={100} alt="logo" />
          </div>
          <div className="mb-4">
            <Typography
              fontWeight={700}
              textAlign={"center"}
              variant="h6"
              textTransform={"uppercase"}
            >
              NMCN Examination Slip
            </Typography>
          </div>
          <div>
            <form onSubmit={getPrintout}>
              <TextField
                onChange={(e) => setExaminationNumber(e.target.value)}
                fullWidth
                label="Enter Examination Number"
              />
              <div className="mt-3 text-center">
                <LoadingButton
                  loading={loading}
                  type="submit"
                  variant="outlined"
                >
                  get slip
                </LoadingButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
