import React, { useState, useContext, useRef, useEffect } from "react";
import {
  Alert,
  Button,
  CircularProgress,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileText } from "@fortawesome/free-regular-svg-icons";
import logo from "../images/logo.png";
import { LoadingButton } from "@mui/lab";
import { httpService } from "../httpService";
import { AlertContext } from "../contexts/AlertContext";
import { useParams } from "react-router-dom";
import QRCode from "react-qr-code";
import ReactToPrint from "react-to-print";
import { Table } from "react-bootstrap";
import { Watermark } from "@hirohe/react-watermark";
export default function PrintOut() {
  const { id } = useParams();
  const [candidate, setCandidate] = useState(null);
  const [papers, setPapers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [time, setTime] = useState("");
  const componentRef = useRef();

  const getData = async () => {
    setLoading(true);
    const { data } = await httpService(id);
    if (data) {
      console.log(data);
      setCandidate(data);
      const rawPapers = data.Papers.split(",");

      const switchIndex = (text) => {
        switch (text) {
          case "paper1":
            return { paper: "Paper I", date: "5th November, 2024" };

          case "paper2":
            return { paper: "Paper II", date: "6th November, 2024" };

          case "paper3":
            return { paper: "Paper III", date: "7th November, 2024" };

          case "practical":
            return { paper: "OSCE Date", date: data.OsceDate };
          default:
            return;
        }
      };
      const paperData = [];
      for (let i = 0; i < rawPapers.length; i++) {
        //const element = array[i];
        paperData.push(switchIndex(rawPapers[i].trim()));
      }

      setPapers(paperData);

      if (data.ExamSession === "1") setTime("7:00 AM");
      else if (data.ExamSession === "2") setTime("9:30 AM");
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div className="mb-5">
      <div ref={componentRef} className="container">
        <div>
          <Watermark
            text="NMCN NOVEMBER EXAMS 2024"
            textSize={40}
            opacity={0.7}
          >
            <div className="mb-2 mt-3  mb-4 text-center">
              <div className="mb-2">
                <img src={logo} height={100} width={100} alt="logo" />
              </div>
              <Typography
                variant="h6"
                fontWeight={700}
                textTransform={"uppercase"}
              >
                Nursing and Midwifery Council of Nigeria
              </Typography>
              <Typography color={"GrayText"} textTransform={"uppercase"}>
                Examination Slip
              </Typography>
            </div>
            {loading && <CircularProgress />}
            <div className="">
              {candidate && (
                <div>
                  <div>
                    <div className="">
                      <div className="mb-2 d-flex justify-content-between">
                        <div className="text-center mb-3">
                          <img
                            style={{ height: 200, width: 200 }}
                            src={`data:image/jpg;base64,${candidate.Passport}`}
                            alt="img"
                          />
                        </div>

                        <div>
                          <QRCode
                            size={200}
                            // value={id}
                            value={JSON.stringify({
                              // passport: candidate.Passport,
                              firstName: candidate.FirstName,
                              middleName: candidate.MiddleName,
                              lastName: candidate.Surname,
                              examNumber: candidate.ExamNumber,
                              school: candidate.School,
                              program: candidate.Program,
                              state: candidate.State,
                            })}
                          />
                        </div>
                      </div>
                      <div className="mb-2">
                        <div>
                          <div className="col-lg-6 text-break">
                            <Typography variant="caption" gutterBottom>
                              Name:
                            </Typography>
                            <Typography fontWeight={700}>
                              {candidate.FirstName} {candidate.MiddleName}{" "}
                              {candidate.Surname}
                            </Typography>
                          </div>
                        </div>
                      </div>
                      <div className="mb-2">
                        <div className=" ">
                          <div className="mb-2">
                            <Typography variant="caption" gutterBottom>
                              Examination Number:
                            </Typography>
                            <Typography
                              fontWeight={700}
                              textTransform={"uppercase"}
                            >
                              {candidate.ExamNumber}
                            </Typography>
                          </div>
                          <div className="mb-2">
                            <Typography variant="caption" gutterBottom>
                              Program:
                            </Typography>
                            <Typography
                              fontWeight={700}
                              textTransform={"uppercase"}
                            >
                              {candidate.Program}
                            </Typography>
                          </div>
                        </div>
                      </div>
                      <div className="mb-2">
                        <Typography variant="caption" gutterBottom>
                          School:
                        </Typography>
                        <Typography
                          fontWeight={700}
                          textTransform={"uppercase"}
                        >
                          {candidate.School}
                        </Typography>
                        {/* <div className="mb-3">
                      <Typography variant="caption">State:</Typography>
                      <Typography fontWeight={700}>
                        {candidate.State}
                      </Typography>
                    </div> */}
                      </div>
                      <div className="mb-2">
                        <Stack direction={"row"} spacing={2}>
                          <div>
                            <Typography variant="caption" gutterBottom>
                              Session
                            </Typography>
                            <Typography fontWeight={700}>
                              Session {candidate.ExamSession}
                            </Typography>
                          </div>
                          <div>
                            <Typography variant="caption" gutterBottom>
                              Time
                            </Typography>
                            <Typography fontWeight={700}>{time}</Typography>
                          </div>
                          <div className=" ">
                            <Typography gutterBottom variant="caption">
                              Centre ID:
                            </Typography>
                            <Typography fontWeight={700}>
                              {candidate.CentreID}
                            </Typography>
                          </div>
                          <div className=" ">
                            <Typography gutterBottom variant="caption">
                              Seat No:
                            </Typography>
                            <Typography fontWeight={700}>
                              {candidate.SeatNo}
                            </Typography>
                          </div>
                        </Stack>
                      </div>

                      <div className="mb-2">
                        <Typography variant="caption">Papers:</Typography>
                        <div className="mt-2">
                          <div className="col-lg-6">
                            <Table striped bordered>
                              <thead>
                                <tr>
                                  <th>Paper</th>
                                  <th>Date</th>
                                </tr>
                              </thead>
                              <tbody>
                                {papers.map((c, i) => (
                                  <tr key={i}>
                                    <td>
                                      <Typography>{c?.paper}</Typography>
                                    </td>
                                    <td>
                                      <Typography>{c?.date}</Typography>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {!loading && !candidate && (
                <div className="col-lg-4">
                  <Alert severity="error">no candidate found</Alert>
                </div>
              )}
            </div>
            {candidate && (
              <div className="">
                <div className="mb-2">
                  <Typography variant="caption">CBT Venue:</Typography>
                  <Typography fontWeight={700}>
                    {candidate.ExamCentre}
                  </Typography>
                </div>
                <div className="mb-2">
                  <Typography variant="caption">OSCE Centre:</Typography>
                  <Typography fontWeight={700}>
                    {candidate.OsceCentre}
                  </Typography>
                </div>
                {/* <div className="mt-2 ">
                  <Typography color="error">
                    NOTE: You will not be examined unless you bring this card
                    with you to the written, oral and practical examinations. No
                    duplicate will be issued.
                  </Typography>
                </div> */}
              </div>
            )}
          </Watermark>
        </div>
      </div>
      <div className="container">
        {!loading && (
          <ReactToPrint
            trigger={() => {
              return (
                <Button
                  // disabled={
                  //   !participants.every((c) => c.status === "complete")
                  // }
                  variant="contained"
                >
                  print slip
                </Button>
              );
            }}
            content={() => componentRef.current}
          />
        )}
      </div>
    </div>
  );
}
